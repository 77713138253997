import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
// eslint-disable-next-line deprecation/deprecation
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

/* ------ CORE IMPORTS -----------  */
import { CoreFrontendSharedModule } from '@core/frontend-shared';
import { CoreRouterModule, provideRootRoutes, routerProviderFactory } from '@core/frontend-shared/router';

/* ------  COMMON IMPORTS -----------  */
import { ConsoleFrontendSharedModule } from '@common/console/frontend-shared';
import { LayoutFrontendSharedModule } from '@common/layout/frontend-shared';
import { LayoutContentbarsModule } from '@common/layout/frontend-shared/contentbars';
import { LayoutRouterOutletUiFullscreenModule } from '@common/layout/frontend-shared/router-outlet-fullscreen';
import { LayoutSidebarModule } from '@common/layout/frontend-shared/sidebar';
import { LocalstateFrontendSharedModule } from '@common/localstate/frontend-shared';
import { MessagesFrontendModule } from '@common/messages/frontend';
import { UiScreenServiceModule } from '@common/ui/frontend-shared/screen-service';
import { VersioncheckFrontendModule } from '@common/versioncheck/frontend';
import { UtilsFrontendSharedModule } from '@core/frontend-shared/utils';

// /* ------ OTHER FEATURE IMPORTS -----------  */
import { UiRequiredModule } from '@pflegetheke/ui/frontend-shared';

/* ------  LOCAL IMPORTS -----------  */
import { UserForceNewPasswordComponent } from '@common/users/frontend-shared';
import { AppComponent } from './components/app.component';
import { HomeComponent } from './components/home.component';

import { PflegethekeOptinBundleModule } from '@pflegetheke/optin-bundle/frontend-shared';
import features from './features';
import { MinimalUiModule } from '@common/ui/frontend-shared';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';


registerLocaleData(localeDe);



const states: any[] = [];

const persist: string[] = ['access','local','language'];

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
	],
	imports: [
		ServiceWorkerModule.register('ngsw-worker.js', { 
			enabled: IS_PRODUCTION, 
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000' 
		}),
		BrowserModule,
		ReactiveFormsModule,
		
		// must be imported BEFORE Core library!
		NgxsModule.forRoot(states, { 
			developmentMode: !IS_PRODUCTION,
			selectorOptions: { suppressErrors: false }
		}),
		NgxsStoragePluginModule.forRoot({ key: persist }),
		(!IS_PRODUCTION) ? NgxsReduxDevtoolsPluginModule.forRoot({}) : [],

		CoreFrontendSharedModule.forRoot({}),
		UserForceNewPasswordComponent,
		
		RouterModule.forRoot([/* routes provided using provideRootRoutes()! */], { 
			initialNavigation: 'enabledBlocking',
			paramsInheritanceStrategy:'always'
		}),
		CoreRouterModule,
		BrowserAnimationsModule,
		ConsoleFrontendSharedModule,
		UiScreenServiceModule.forRoot(),
		MinimalUiModule.forRoot(),
		UiRequiredModule,
		MessagesFrontendModule,
		
		UtilsFrontendSharedModule,
		LocalstateFrontendSharedModule,
		LayoutFrontendSharedModule.forRoot(),
		LayoutSidebarModule,
		LayoutContentbarsModule,
		LayoutRouterOutletUiFullscreenModule,
		VersioncheckFrontendModule,

		PflegethekeOptinBundleModule,

		...features,
	],
	providers: [
		{provide: LOCALE_ID, useValue: "de-DE"},
		provideHttpClient(
			withFetch(),
			withInterceptorsFromDi(),
		),
		provideRootRoutes([
			{ path: '', pathMatch: 'full', component: HomeComponent, data: {pageTitle:'Startseite'}	},
		]),
		...routerProviderFactory(),
	],
	bootstrap: [AppComponent],
	exports: [
		// CommonModule,
		UiRequiredModule,
	]
})
export class AppModule {

}
